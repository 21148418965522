import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import axios from 'axios';
import styles from './index.module.scss';
import Input from '../../../components/Input';
import StoreContext from '../../../state/context/store';
import { login, setMe } from '../../../state/actions/me';
import { rootUrl } from '../../../config';
import { Button } from '@material-ui/core';

const Login = (props) => {
  const { history } = props;
  const { t } = useTranslation();
  const [, dispatch] = useContext(StoreContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const handleLogin = async () => {
    await axios
      .post(
        `${rootUrl}/api/login`,
        {
          email,
          password,
          application: 'orca',
        },
        { withCredentials: true }
      )
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          login(dispatch, response.data);
          setMe(dispatch, response.data);
          history.push(`/profile/${response.data.id}`);
        }
      })
      .catch((error, sdf) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          setPasswordError(error.response.data.error);
        } else {
          setPasswordError('There was an unknown error. Please try again');
        }
      });
  };

  const save = () => {
    if (!email) {
      setEmailError('Your email is required');
    }
    if (!password) {
      setPasswordError('A password is required');
    }
    if (email && password) {
      handleLogin();
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      save();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.image}></div>
      <h1 className={styles.title}>{t('Welcome back to Orca!')}</h1>
      <h3 className={styles.subhead}>
        {t('Not a member yet?')}
        <span
          className={styles.signup}
          onClick={() => history.push('/sign-up')}
        >
          {t('Sign Up')}
        </span>
      </h3>

      <h3 className={styles.pleaseLogin}>
        {t(
          'Please log in using the email address associated with your account'
        )}
      </h3>
      <form className={styles.form}>
        <label className={styles.loginLabel} htmlFor="email">
          {t('Email')}
        </label>
        <Input
          className={styles.loginInput}
          type="text"
          maxLength={255}
          id="email"
          onChange={(e) => {
            setEmailError('');
            setEmail(e.target.value);
          }}
          error={emailError}
        />
        <label className={styles.loginLabel} htmlFor="pw">
          {t('Password')}
        </label>
        <Input
          className={styles.loginInput}
          type="password"
          maxLength={255}
          id="pw"
          onKeyPress={handleKeyPress}
          onChange={(e) => {
            setPasswordError('');
            setPassword(e.target.value);
          }}
          error={t(passwordError)}
        />
        <Link className={styles.forgotPassword} to="/forgot-password">
          {t('forgot password?')}
        </Link>
      </form>

      <div className={styles.login}>
        <Button
          className={`${styles.purpleButton} ${styles.loginButton}`}
          onClick={save}
        >
          {t('Login')}
        </Button>
      </div>
      <div className={styles.privacyWrapper}>
        <a
          className={styles.privacy}
          style={{ marginLeft: 'auto' }}
          href="mailto:pods@500womenscientists.org"
          rel="noopener noreferrer"
        >
          {t('Contact Us')}
        </a>
      </div>
    </div>
  );
};

export default Login;
