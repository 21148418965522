import React from 'react';
import { useTranslation } from 'react-i18next';

import LeftNavigation from '../../components/LeftNavigation';
import styles from './index.module.scss';
import '@fortawesome/fontawesome-free';
import 'components-font-awesome';
import ALISON_MARKLEIN from '../../assets/images/team/ALISON_MARKLEIN.jpg';
import Ana_Carvalho from '../../assets/images/team/Ana_Carvalho.jpg';
import KELLY_RAMIREZ from '../../assets/images/team/KELLY_RAMIREZ.jpg';
// import Myndi_Holbrook from '../../assets/images/team/Myndi_Holbrook.jpeg';
import Nicole_Williams from '../../assets/images/team/Nicole_Williams.jpg';
// import Amanda_Obidike from '../../assets/images/team/Amanda_Obidike.jpg';
import Teal_Potter from '../../assets/images/team/Teal_Potter.jpg';
import Chinmayi_Balusu from '../../assets/images/team/Chinmayi_Balusu.jpg';
import Jill_Blackford from '../../assets/images/team/Jill_Blackford.png';

import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Container,
  Grid,
  //Link,
  Paper,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    flexGrow: 1,
    overflow: 'auto',
    height: '100%',
    padding: 40,
  },
  large: {
    width: 356,
    height: 356,
    margin: 'auto',
    '-webkit-filter': 'grayscale(100%)',
    filter: 'grayscale(100%)',
  },
  paper: {
    padding: theme.spacing(4),
    boxShadow: '0px 5px 9px 0 rgba(65,36,77,0.05)',
    borderRadius: 0,
  },
  header: {
    marginBottom: 20,
    fontWeight: 600,
    color: '#2C0040',
    fontFamily: 'Oswald',
    fontSize: '20px',
    letterSpacing: '.5px',
  },
  subHeader: {
    // marginBottom: 30,
    fontFamily: "'Archivo Narrow'",
  },
  body: {
    margin: 'auto',
  },
  grid: {
    minWidth: 400,
  },
  nameWrapper: {
    marginBottom: 10,
    marginTop: 10,
  },
  link: {
    color: '#6e3694',
    marginTop: 20,
    textDecoration: 'none',
    fontFamily: "'Archivo Narrow'",
    fontWeight: 500,
    '&:hover': {
      color: '#2c0040',
      textDecoration: 'underline',
    },
  },
  emailLink: {
    marginTop: 20,
  },
  title: {
    color: '#2C0040',
    fontFamily: 'Oswald',
    fontSize: '18px',
    fontWeight: 800,
    letterSpacing: '0.5px',
    marginTop: 20,
    marginBottom: 12,

    display: 'inline',
  },
  noun: {
    color: 'rgba(26,26,26,0.5)',
    fontFamily: 'Oswald',
    fontSize: '16px',
    fontWeight: 300,
    display: 'inline',
    paddingLeft: 5,
  },

  teamWrapper: {
    // paddingRight: 250,
    marginTop: 30,
  },
  member: {
    minHeight: 776, //580
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  bio: {
    color: 'rgba(20, 20, 20, 0.7)',
    fontFamily: 'Archivo Narrow',

    flex: 1,
  },
  bioContinued: {
    // display: "none"
  },
  more: {
    color: '#6E3694',
    fontSize: '1rem',
    fontFamily: 'Archivo Narrow',
    fontWeight: 400,
    lineHeight: '1.5',
    letterSpacing: '0.00938em',
    textDecoration: 'none',
  },
}));

const Team = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  // const moreClicked = (e) => {
  //   e.preventDefault();
  //   //hide more link
  //   e.currentTarget.hidden = true;
  //   //show continued bio
  //   e.currentTarget.nextElementSibling.hidden = false;
  // };

  const team = [
    {
      name: (
        <div className={classes.nameWrapper}>
          <Typography variant="h5" className={classes.title}>
            Ana Paula S. Carvalho
          </Typography>
          <Typography variant="body1" className={classes.noun}>
            ({t('she/her')})
          </Typography>
        </div>
      ),
      email: (
        <a className={classes.link} href="mailto:ana@500womenscientists.org">
          <i style={{ paddingRight: 5 }} className="far fa-envelope"></i>
          ana@500womenscientists.org
        </a>
      ),
      image: (
        <Avatar
          variant="square"
          alt="Ana Paula S. Carvalho"
          className={classes.large}
          src={Ana_Carvalho}
        />
      ),
      bio: (
        <Typography variant="body1" align="left" className={classes.bio}>
          {t(`carvalho_profile`)}
        </Typography>
      ),
    },
    {
      name: (
        <div className={classes.nameWrapper}>
          <Typography variant="h5" className={classes.title}>
            Alison Marklein
          </Typography>
          <Typography variant="body1" className={classes.noun}>
            ({t('she/her')})
          </Typography>
        </div>
      ),
      email: (
        <a className={classes.link} href="mailto:pods@500womenscientists.org">
          <i style={{ paddingRight: 5 }} className="far fa-envelope"></i>
          pods@500womenscientists.org
        </a>
      ),
      image: (
        <Avatar
          variant="square"
          alt="Alison Marklein"
          className={classes.large}
          src={ALISON_MARKLEIN}
        />
      ),
      bio: (
        <Typography variant="body1" align="justify" className={classes.bio}>
          {t(
            `marklein_proflie`
            //Alison is also on the Executive Leadership Team for 500 Women Scientists, where she facilitates and oversees projects that “Harness Our Collective Power.
          )}
          {/* <Link href="#" className={classes.more} onClick={moreClicked}>
            more
          </Link>
          <span hidden={true} className={classes.bioContinued}>
            {t(
              `other activities to support the Pods. Alison is also on the Executive Leadership Team for 500 Women Scientists, where she facilitates and oversees projects that “Harness Our Collective Power.”`
            )}
          </span> */}
        </Typography>
      ),
    },
    {
      name: (
        <div className={classes.nameWrapper}>
          <Typography variant="h5" className={classes.title}>
            Nicole Williams
          </Typography>
          <Typography variant="body1" className={classes.noun}>
            ({t('she/her')})
          </Typography>
        </div>
      ),
      email: (
        <a className={classes.link} href="mailto:nawilliams516@gmail.com">
          <i style={{ paddingRight: 5 }} className="far fa-envelope"></i>
          nawilliams516@gmail.com
        </a>
      ),
      image: (
        <Avatar
          variant="square"
          alt="Nicole Williams"
          className={classes.large}
          src={Nicole_Williams}
        />
      ),
      bio: (
        <Typography variant="body1" align="justify" className={classes.bio}>
          {t(`williams_proflie`)}
          {/* <Link href="#" className={classes.more} onClick={moreClicked}>
            more
          </Link>
          <span hidden="true" className={classes.bioContinued}>
            {t(
              `on reaching out to all inactive pods, finding and supporting regional pod coordinators, and developing materials to train new pod coordinators. She is also the co-founder of the Black Women Scientist Initiative which was created as a space to a) highlight work that black women are doing in STEM, b) using our platform to speak out against racism, patriarchy, and oppressive societal norms; and c) to collaborate and learn from each other as we seek to make long lasting change.`
            )}
          </span> */}
        </Typography>
      ),
    },
    // {
    //   name: (
    //     <div className={classes.nameWrapper}>
    //       <Typography variant="h5" className={classes.title}>
    //         Amanda Obidike
    //       </Typography>
    //       <Typography variant="body1" className={classes.noun}>
    //         ({t("she/her")})
    //       </Typography>
    //     </div>
    //   ),
    //   email: (
    //     <a className={classes.link} href="mailto:amandachirpy@gmail.com">
    //       <i style={{ paddingRight: 5 }} class="far fa-envelope"></i>
    //       amandachirpy@gmail.com
    //     </a>
    //   ),
    //   image: (
    //     <Avatar
    //       variant="square"
    //       alt="Amanda Obidike"
    //       className={classes.large}
    //       src={Amanda_Obidike}
    //     />
    //   ),
    //   bio: (
    //     <Typography variant="body1" align="justify" className={classes.bio}>
    //       {t(`Coming soon...`)}
    //     </Typography>
    //   ),
    // },
    {
      name: (
        <div className={classes.nameWrapper}>
          <Typography variant="h5" className={classes.title}>
            Chinmayi Balusu
          </Typography>
          <Typography variant="body1" className={classes.noun}>
            ({t('she/her')})
          </Typography>
        </div>
      ),
      email: (
        <a className={classes.link} href="mailto:chinmayi.balusu@gmail.com">
          <i style={{ paddingRight: 5 }} className="far fa-envelope"></i>
          chinmayi.balusu@gmail.com
        </a>
      ),
      image: (
        <Avatar
          variant="square"
          alt="Chinmayi Balusu"
          className={classes.large}
          src={Chinmayi_Balusu}
        />
      ),
      bio: (
        <Typography variant="body1" align="justify" className={classes.bio}>
          {t(`balusu_proflie`)}
          {/* <Link href="#" className={classes.more} onClick={moreClicked}>
            more
          </Link>
          <span hidden="true" className={classes.bioContinued}>
            {t(
              `as needed and is currently brainstorming website reorganization for easy accessibility of information for pods.`
            )}
          </span> */}
        </Typography>
      ),
    },
    {
      name: (
        <div className={classes.nameWrapper}>
          <Typography variant="h5" className={classes.title}>
            Jill Blackford
          </Typography>
          <Typography variant="body1" className={classes.noun}>
            ({t('she/her')})
          </Typography>
        </div>
      ),
      email: (
        <a className={classes.link} href="mailto:pods@500womenscientists.org">
          <i style={{ paddingRight: 5 }} className="far fa-envelope"></i>
          pods@500womenscientists.org
        </a>
      ),
      image: (
        <Avatar
          variant="square"
          alt="Jill Blackford"
          className={classes.large}
          src={Jill_Blackford}
        />
      ),
      bio: (
        <Typography variant="body1" align="justify" className={classes.bio}>
          {t(`blackford_proflie`)}
          {/* <Link href="#" className={classes.more} onClick={moreClicked}>
            more
          </Link>
          <span hidden="true" className={classes.bioContinued}>
            {t(
              `Institute for New Economic Thinking, education in southern and eastern Africa with the ELMA Philanthropies, and research on nonprofit and foundation issues at the Aspen Institute. She is currently the co-chair of the 500WS Advisory Board and she also serves as Research and Development Advisor to the organization Climate Refugees, which defends the rights of human beings displaced across borders as a result of climate change. She holds a Masters of International Affairs focused in Economic and Political Development from Columbia University’s School of International and Public Affairs and a Bachelor’s degree in international relations from Boston University.`
            )}
          </span> */}
        </Typography>
      ),
    },
    {
      name: (
        <div className={classes.nameWrapper}>
          <Typography variant="h5" className={classes.title}>
            Kelly Ramirez
          </Typography>
          <Typography variant="body1" className={classes.noun}>
            ({t('she/her')})
          </Typography>
        </div>
      ),
      email: (
        <a className={classes.link} href="mailto:kellysramirez@gmail.com">
          <i style={{ paddingRight: 5 }} className="far fa-envelope"></i>
          kellysramirez@gmail.com
        </a>
      ),
      image: (
        <Avatar
          variant="square"
          alt="Kelly Ramirez"
          className={classes.large}
          src={KELLY_RAMIREZ}
        />
      ),
      bio: (
        <Typography variant="body1" align="justify" className={classes.bio}>
          {t(`ramirez_proflie`)}
        </Typography>
      ),
    },
    // {
    //   name: (
    //     <div className={classes.nameWrapper}>
    //       <Typography variant="h5" className={classes.title}>
    //         Myndi Holbrook
    //       </Typography>
    //       <Typography variant="body1" className={classes.noun}>
    //         ({t("she/her")})
    //       </Typography>
    //     </div>
    //   ),
    //   email: (
    //     <a className={classes.link} href="mailto:myndih5@gmail.com">
    //       <i style={{ paddingRight: 5 }} class="far fa-envelope"></i>
    //       myndih5@gmail.com
    //     </a>
    //   ),
    //   image: (
    //     <Avatar
    //       variant="square"
    //       alt="Myndi Holbrook"
    //       className={classes.large}
    //       src={Myndi_Holbrook}
    //     />
    //   ),
    //   bio: (
    //     <Typography variant="body1" align="justify" className={classes.bio}>
    //       {t(
    //         `Is a laboratory technician working in Hamilton, MT as a member of the Laboratory of Virology COVID-19 Research Group within the National Institutes of Health (NIH). She hopes to help develop and distribute new resources for `
    //       )}
    //       <Link href="#" className={classes.more} onClick={moreClicked}>
    //         more
    //       </Link>
    //       <span hidden="true" className={classes.bioContinued}>
    //         {t(
    //           `pods so that it’s easy for pods to get member engagement and host different events.`
    //         )}
    //       </span>
    //     </Typography>
    //   ),
    // },
    {
      name: (
        <div className={classes.nameWrapper}>
          <Typography variant="h5" className={classes.title}>
            Teal Potter
          </Typography>
          <Typography variant="body1" className={classes.noun}>
            ({t('she/her')})
          </Typography>
        </div>
      ),
      email: (
        <a className={classes.link} href="mailto:teal.potter@gmail.com">
          <i style={{ paddingRight: 5 }} className="far fa-envelope"></i>
          teal.potter@gmail.com
        </a>
      ),
      image: (
        <Avatar
          variant="square"
          alt="Teal Potter"
          className={classes.large}
          src={Teal_Potter}
        />
      ),
      bio: (
        <Typography variant="body1" align="justify" className={classes.bio}>
          {t(`potter_proflie`)}
          {/* <Link href="#" className={classes.more} onClick={moreClicked}>
            more
          </Link>
          <span hidden="true" className={classes.bioContinued}>
            {t(`and supports pod coordinators on Slack.`)}
          </span> */}
        </Typography>
      ),
    },
  ];

  //get pod from query string
  return (
    <div className={styles.container}>
      <LeftNavigation></LeftNavigation>
      <Container maxWidth="xl" className={classes.container}>
        <Paper
          maxWidth="xl"
          className={`${classes.paper} ${styles.headerWrapper}`}
        >
          <Typography className={classes.header} variant="h4" align="left">
            {t('Introducing the Pods Wolfpack')}
          </Typography>
          <Typography
            className={classes.subHeader}
            variant="body1"
            align="justify"
          >
            {t(`wolfpack_text`)}
          </Typography>
          <Typography
            className={classes.emailLink}
            variant="body1"
            align="left"
          >
            <a
              className={classes.link}
              href="mailto:pods@500womenscientists.org"
            >
              <i style={{ paddingRight: 5 }} className="far fa-envelope"></i>
              pods@500womenscientists.org
            </a>
          </Typography>
        </Paper>

        <Grid className={classes.teamWrapper} container spacing={4}>
          {team.map((member, n) => (
            <Grid key={n} item xs={4} className={classes.grid}>
              <Paper className={`${classes.paper} ${classes.member}`}>
                {member.image}
                {member.name}
                {member.bio}
                {/* {member.email} */}
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default Team;
