import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LngLat } from 'mapbox-gl';
import styles from './left-panel.module.scss';
import myPodSVG from '../../assets/images/pods/podPurple.svg';
import inactivePodSVG from '../../assets/images/pods/inactiveSymbol.png';
import zoomIcon from '../../assets/images/pods/icons/Search.svg';
import closeIcon from '../../assets/images/pods/icons/Close.svg';

import {
  Button,
  TextField,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popper,
  Typography,
  ClickAwayListener,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import '@fortawesome/fontawesome-free';
import 'components-font-awesome';

const useStyles = makeStyles((theme) => ({
  popper: {
    backgroundColor: 'white',
    zIndex: 6,
    marginLeft: -35,
    maxWidth: 297,
    '& .MuiListItemIcon-root': {
      minWidth: 30,
    },
  },
  gold: {
    color: 'gold',
  },
  nearbyPods: {
    paddingTop: 10,
    fontFamily: 'Oswald',
    fontSize: '16px',
    fontWeight: 500,
    letterSpacing: 0,
    color: '#2C0040',
  },
  globalText: {
    color: '#6e3694',
    fontWeight: 800,
  },
}));

let bouncer = null;
const LeftPanel = (props) => {
  const classes = useStyles();
  const {
    searchEvent,
    map,
    features,
    adding,
    setAdding,
    setDragCoords,
    rawData,
    myPods,
  } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openList, setOpenList] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const handleClickAway = (e) => {
    if (e.target.id !== 'search-input') {
      setOpenList(false);
    }
  };

  const searchFocused = (e) => {
    setAnchorEl(e.currentTarget);
    setOpenList(true);
  };

  //if user pressed enter, reperform query (usered could have changed the bounding box)
  const searchKeyed = (e) => {
    if (e.keyCode === 13) {
      performSearch(searchText);
    }
  };

  const searchChanged = (e) => {
    const val = e.target.value;
    setSearchText(val);

    clearTimeout(bouncer);
    bouncer = setTimeout(() => {
      performSearch(val);
    }, 400);
  };

  const performSearch = (text) => {
    try {
      //keep bounds within -180 and 180
      if (map?.current?.getBounds && rawData.length) {
        // const bbox = map?.current
        //   ?.getBounds()
        //   .toArray()
        //   .flat()
        //   .map((b) => {
        //     if (b < -180) {
        //       return -180;
        //     } else if (b > 180) {
        //       return 180;
        //     }
        //     return b;
        //   });
        //   &bbox=${bbox.join(
        //     ','
        //   )}
        // if (bbox) {
        //removing points of interest from results, like airports
        fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${text}.json?access_token=${process.env.REACT_APP_PODS_MAPBOX_TOKEN}&language=en-US&limit=5&types=country,region,district,place,locality`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/vnd.geo+json',
            },
          }
        ).then(async function (response) {
          if (response.status === 200) {
            const json = await response.json();
            if (json?.features) {
              setOpenList(true);
              setSearchResults(json.features);
            }
          }
        });
        //}
      }
    } catch (error) {
      console.error(error);
    }
  };

  const resultClicked = ({ target }) => {
    const item = searchResults.find(
      (f) => f.id === target.parentElement.parentElement.id
    );
    if (item) {
      setSearchText(item.place_name);

      if (adding) {
        if (item.geometry) {
          setDragCoords(
            new LngLat(
              item.geometry.coordinates[0],
              item.geometry.coordinates[1]
            )
          );
          if (map.current.getZoom() < 5.5) {
            map.current.flyTo({ center: item.geometry.coordinates, zoom: 6 });
          }
        }
      } else {
        //send back to parent
        searchEvent(item, searchText);
      }

      setOpenList(false);
    }
  };

  const addPod = () => {
    clearSearch();
    setAdding(true);
  };

  const cancelAdd = () => {
    clearSearch();
    setAdding(false);
    setDragCoords(null);
  };

  const featureClicked = ({ target }) => {
    //zoom to point?
    const feature = features.find(
      (f) =>
        f.properties.id ===
        parseInt(target.parentElement.parentElement.getAttribute('feature'))
    );
    if (feature) {
      map.current.flyTo({ center: feature.geometry.coordinates, zoom: 9 });
    }
  };

  const clearSearch = () => {
    setSearchResults([]);
    searchEvent(null);
    setOpenList(false);
    setSearchText('');
  };

  const getSymbol = (feature) => {
    const data = rawData.find((data) => data.id === feature.properties.id);

    if (myPods && myPods.indexOf(data.id) > -1) {
      return (
        <ListItemIcon>
          <img src={myPodSVG} alt="my pod" className={styles.myPod} />
        </ListItemIcon>
      );
    } else if (data && data.podCoordinators.length) {
      return (
        <ListItemIcon>
          <i className={`fa fa-star ${styles.pod}`} aria-hidden="true"></i>
        </ListItemIcon>
      );
    } else if (data && !data.podCoordinators.length) {
      return (
        <ListItemIcon>
          <img
            src={inactivePodSVG}
            alt="inactive pod"
            className={styles.inactive}
          />
        </ListItemIcon>
      );
    }

    return null;
  };

  return (
    <>
      <div className={styles.root}>
        <div className={styles.header}>
          <Typography variant="h5">
            {!adding ? t('find_pod') : t('request_pod')}
          </Typography>
        </div>
        <div className={styles.body}>
          {!adding && (
            <Typography variant="body1" className={styles.globalText}>
              {t('join_pod_community')}
            </Typography>
          )}
          {adding && (
            <Typography variant="body1" className={styles.helperText}>
              {t('search_pod_location')}
            </Typography>
          )}
          <TextField
            variant="outlined"
            id="search-input"
            className={styles.searchInput}
            autoComplete="off"
            value={searchText}
            placeholder={t('search_pod_by_location')}
            onKeyDown={searchKeyed}
            onChange={searchChanged}
            onFocus={searchFocused}
            InputProps={{
              autoComplete: 'off',
              startAdornment: (
                <InputAdornment position="start">
                  <img src={zoomIcon} alt="search" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={clearSearch}>
                    <img src={closeIcon} alt="close" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <ClickAwayListener onClickAway={handleClickAway}>
            <Popper
              id="simple-popper"
              open={openList}
              anchorEl={anchorEl}
              className={classes.popper}
              placement="bottom-start"
            >
              <List component="nav">
                {searchResults.map((f) => (
                  <ListItem button id={f.id} key={f.id} onClick={resultClicked}>
                    <ListItemIcon>
                      <i className="fas fa-map-marker-alt" />
                    </ListItemIcon>
                    <ListItemText primary={f.place_name} />
                  </ListItem>
                ))}
              </List>
            </Popper>
          </ClickAwayListener>
          {features.length > 0 && (
            <Typography variant="h6" className={classes.nearbyPods}>
              {t('nearby_pods') + ':'}
            </Typography>
          )}
        </div>
        <div className={styles.content}>
          <List>
            {features.map((f) => (
              <ListItem
                className={styles.featureList}
                button
                id={`features${f.properties.id}`}
                key={`features${f.properties.id}`}
                feature={f.properties.id}
                onClick={featureClicked}
              >
                <ListItemText primary={f.properties.name} />
                {!f.empty && getSymbol(f)}
              </ListItem>
            ))}
          </List>
        </div>
        <div className={styles.footer}>
          {!adding && <Typography variant="h6">{t('missing_pod')}</Typography>}
          {!adding && (
            <Button className={styles.goldButton} onClick={addPod}>
              {t('request_pod')}
            </Button>
          )}

          {adding && (
            <Typography variant="h6">{t('request_pod_where')}</Typography>
          )}
          {adding && (
            <Button className={styles.cancelButton} onClick={cancelAdd}>
              {t('Cancel')}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default LeftPanel;
