import React from 'react';
import LeftNavigation from '../../components/LeftNavigation';
import styles from './index.module.scss';

// components
import PodForm from './form';

const PodsPage = (props) => {
  const { match, history } = props;

  //get pod from query string
  return (
    <div className={styles.container}>
      <LeftNavigation></LeftNavigation>
      <PodForm className={styles.form} match={match} history={history} />
    </div>
  );
};

export default PodsPage;
