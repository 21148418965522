import React from 'react';
import styles from './index.module.scss';

const CookiePolicy = props => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Cookie Notice</h1>
      <div className={styles.body}>
        <em>Effective Date: January 6th, 2019</em>
        <p>
          This Cookie Notice supplements our{' '}
          <a href="./privacy">Privacy Notice</a>.
        </p>
        <strong>1. Cookies – What Are They and How Do We Use Them? </strong>
        <p>
          500 Women Scientists (“500WS”, “we”, “us”, “ours”) use cookies and
          similar technologies to collect information about the use of our
          website in order to make it function effectively and also to improve
          our website visitors’ experience when they browse our website. A
          cookie is a small file of letters and numbers that is sent to your
          browser and stored on the hard drive of your computer (or Internet
          enabled device) when you visit a website. Session cookies are
          temporary cookie files that are erased from your device when you close
          your browser. Persistent cookies are cookie files that stay in one of
          your browser's subfolders after you close your browser.
        </p>
        <strong>2. Blocking Cookies</strong>
        <p>
          You do not have to accept all of the cookies used by our website.
          Certain cookies are strictly necessary for the performance of our
          website. If you reject certain cookies, however, you may not be able
          to use some of the features of this website. Others enhance the
          functionality, and if you reject those cookies, you may not be able to
          use some of the features of this website. The types of cookies that we
          use on our website are set out below. Your browser may be configured
          to allow you to refuse or delete cookies or to be notified when a
          cookie is stored on your device. To change your web browser settings
          for cookies, you can follow the instructions in the help section of
          your web browser or visit{' '}
          <a href="https://www.allaboutcookies.org">allaboutcookies.org</a>.
        </p>
        <strong>3. What Do We Collect?</strong>
        <p>We use the following types of cookies on our website:</p>
        <ul>
          <li>Strictly Necessary Cookies</li>
          <li>Performance Cookies</li>
          <li>Functional Cookies</li>
          <li>
            Google Analytics Cookies (may encompass some of the above cookies)
          </li>
        </ul>
        <strong>4. Links to Other Sites</strong>
        <p>
          This notice and our <a href="./privacy">Privacy Notice</a> only cover
          the 500WS and gage. Discover Brilliance websites. Our website,
          newsletters, email updates and other digital communications may
          contain links to and from other third-party websites. When you click
          on these links, you are exiting our website, and the personal data
          that you provide through such third-party websites is not subject to
          our privacy notice(s)/policies. If you follow any link to a
          third-party website, please note that that website will have its own
          privacy notice(s) which set out how your information is collected and
          processed by them. We recommend that you check the privacy
          notices/policies of each website you visit. Some of these third
          parties may use applications that track your online activities across
          different websites and platforms over time.
        </p>
        <strong>5. Google Analytics</strong>
        <p>
          Our gage. Discover Brilliance website uses Google Analytics, a web
          analysis service provided by Google Inc. ("Google"). Google Analytics
          works using cookies. Google Analytics cookies collect your IP address.
          We use the information collected by Google Analytics cookies to find
          out about how visitors use our website. You can prevent Google
          Analytics cookies from being stored by setting your browser software
          accordingly. You can also opt-out of Google Analytics by downloading
          and installing the browser plug-in available at{' '}
          <a href="https://tools.google.com/dlpage/gaoptout">google.com</a>.
        </p>
        <strong>6. Other Information</strong>
        <p>
          Any personal data that we collect from the application of cookies are
          stored on servers that are hosted by our third-party provider in the
          United States. For residents of the European Economic Area (EEA) the
          international transfer of this data is subject to the EU Commission
          approved Standard Contractual Clauses and a data processor agreement
          with the vendor. We retain personal data collected by means of cookies
          needed for personalized services where you have consented to the use
          of cookies for those purposes, and otherwise only for as long as
          necessary to aggregate personal data that we use for website
          performance and audience measurement.
        </p>
        <p>
          If you are an EU resident, you have certain rights in relation to any
          personal data that we process about you. These rights are set out in
          our <a href="./privacy#eu">EU Privacy Notice</a>.
        </p>
      </div>
    </div>
  );
};

export default CookiePolicy;
