import { IconButton } from '@material-ui/core';
import React from 'react';
import styles from './index.module.scss';
import '@fortawesome/fontawesome-free';
import 'components-font-awesome';

const indent = { marginLeft: '1rem' };

const Privacy = (props) => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title} style={indent}>
        <IconButton
          aria-label="close"
          className={styles.close}
          onClick={() => props.history.goBack()}
        >
          <i className="fas fa-times fa-3x" />
        </IconButton>
        Privacy Policy
      </h1>

      <div className={styles.body}>
        <em>Effective Date: January 6th, 2019</em>

        <p>
          This privacy policy sets out how 500 Women Scientists (500WS from
          herein) collects, uses and stores personal data, including via its
          website. References to “we” or "us" are to 500WS.
        </p>
        <p>
          This privacy policy sets out how 500 Women Scientists (500WS from
          herein) collects, uses and stores personal data, including via its
          website. References to “we” or "us" are to 500WS.
        </p>

        <p>
          If you have any questions please contact us using the details below.
        </p>

        <strong>When do we collect information?</strong>
        <strong style={indent}>We collect information:</strong>
        <ul style={indent}>
          <li>
            when you give it to us <strong>directly</strong>
          </li>
          <li>
            when you give it to us <strong>indirectly</strong>
          </li>
          <li>
            when you give it to us via <strong>social media</strong>
          </li>
          <li>
            when you use our <strong>websites</strong> or <strong>apps</strong>
          </li>
        </ul>

        <strong style={indent}>DIRECTLY</strong>
        <p style={indent}>
          You may give us personal information when you: use our site, donate,
          sign up for one of our resources or events, communicate with us,
          request a speaker, sign up for email newsletters, or leave a comment
          on one of our blogs or social media feeds.
        </p>

        <strong style={indent}>INDIRECTLY</strong>
        <p style={indent}>
          We may obtain your personal information via an external fundraising
          platform when you have donated to us via DonorBox, PayPal, Stripe, or
          another online tool and you have given your consent to process the
          donation. Please check their privacy policies when you give them your
          information.
        </p>

        <strong style={indent}>SOCIAL MEDIA</strong>
        <p style={indent}>
          We may obtain information about you from your social media accounts or
          services when you choose to interact with us, for example when you log
          in to leave a comment on a social media platform such as Facebook,
          Instagram, and Twitter. Please check your settings and the privacy
          policies of the social media sites you use for more information.
        </p>
        <p style={indent}>
          In some cases, we hold publicly available information from social
          media platforms (such as social media handles or number of followers)
          on our systems. Should we want to reach out to a particular social
          media handle, we would be able to do so using the contact information
          provided publicly.
        </p>

        <strong style={indent}>OUR WEBSITES AND APPS</strong>
        <p style={indent}>
          We use “cookies” to help us improve the performance of our website and
          campaigns.
        </p>
        <p style={indent}>Find out more on our cookies policy.</p>
        <p style={indent}>
          Similarly, if you receive an email from 500WS, the email platform used
          collects information on (i) who opened the email and (ii) what content
          was clicked. This allows us to determine which topics are popular and
          better tailor our communications in the future. The data is aggregated
          and not for personal identification.
        </p>

        <strong>What information do we collect?</strong>
        <p style={indent}>
          If you{' '}
          <strong>
            sign up for our gage. Discover Brilliance (formerly Request a Woman
            STEMM) directory
          </strong>
          , we will usually collect your:
        </p>
        <ul style={indent}>
          <li>name</li>
          <li>email address</li>
          <li>city, state/province, country</li>
          <li>field or discipline</li>
          <li>personal or professional keywords</li>
          <li>profile picture</li>
          <li>
            if you identify as coming from underrepresented minority group or
            groups
          </li>
          <li>
            what your interests are (e.g., outreach, speak to a journalist, join
            a panel)
          </li>
        </ul>

        <p style={indent}>
          If you <strong>contact us</strong> through our website contact form or
          directly email us, we usually collect your:
        </p>
        <ul style={indent}>
          <li>name</li>
          <li>email address</li>
        </ul>

        <p style={indent}>
          When you <strong>donate to 500WS using an online platform</strong>,
          you may be asked to provide:
        </p>
        <ul style={indent}>
          <li>
            your financial information (which are handled by payment processors
            used by DonorBox, our digital donor platform)
          </li>
          <li>the address associated with the donation</li>
        </ul>

        <p style={indent}>
          We may also <strong>record</strong>:
        </p>
        <ul style={indent}>
          <li>
            When you leave a comment on our website, we may also collect any
            information you provide to us in your comments or voluntary feedback
            forms.
          </li>
        </ul>

        <p style={indent}>
          When you <strong>sign up for a survey</strong>:
        </p>
        <ul style={indent}>
          <li>information about how you use our directory</li>
        </ul>
        <strong>How do we use the information?</strong>
        <p style={indent}>
          In general, we <strong>use data</strong> to:
        </p>
        <ul style={indent}>
          <li>send you updates and other communications purposes</li>
          <li>
            feature you in our <strong>“gage. Discover Brillance”</strong>{' '}
            directory when you sign up to be featured
          </li>
          <li>answer your enquiries </li>
          <li>
            process and acknowledge your donations to 500WS activities (through
            our digital donor platform)
          </li>
          <li>keep a record of your engagement with us</li>
          <li>understand how we can improve our services and information</li>
          <li>
            analyze <strong>“gage. Discover Brillance”</strong> platform
            activity, both its members and users
          </li>
        </ul>
        <p style={indent}>
          Depending on the reasons you are providing the data to us for, we may
          additionally use it to:
        </p>
        <strong style={indent}>
          INFORMATION PROVIDED FOR ONLINE FORMS AND FEEDBACK
        </strong>
        <p style={indent}>
          We will use your personal information to respond to your questions or
          requests.
        </p>
        <strong style={indent}>ANSWERS TO SURVEYS </strong>
        <p style={indent}>
          We use surveys to understand who uses our Request platform and who
          visits our websites. This helps us create a better platform and make
          our website easier to use.
        </p>
        <p style={indent}>
          Our website includes a permanent link to a survey for the use of
          Request platform users (e.g. journalists, conference organizers).
          Request platform members (women scientists) may receive survey links
          by email sent to their e-mail address, including upon the completion
          of each one year of membership in the database.
        </p>
        <strong style={indent}>INFORMATION RELATED TO DONATIONS</strong>
        <p style={indent}>
          We use a third party platform to process your donations. The third
          party platform stores your information to process and keep a record of
          your donation.
        </p>
        <strong style={indent}>DIRECT COMMUNICATIONS</strong>
        <p style={indent}>
          We use direct communication to let you know what 500WS is doing and
          how your support makes a difference. We will always respect your
          preferences and endeavor to send you information that you might find
          interesting, in the format you prefer.
        </p>
        <p style={indent}>
          The types of communications that you can expect to receive from us
          include:
        </p>
        <ul style={indent}>
          <li>Monthly updates on 500WS activities and initiatives</li>
          <li>Surveys to assess if we are meeting your needs</li>
        </ul>
        <p style={indent}>
          For e-mail communication, you can update your preferences anytime or
          opt out by using the unsubscribe link in the footer of each email. You
          may also e-mail us at{' '}
          <a href="mailto:pods@500womenscientists.org?subject=opt-out">
            pods@500womenscientists.org
          </a>{' '}
          to opt out at any time.
        </p>
        <strong style={indent}>SOCIAL MEDIA</strong>
        <p style={indent}>
          We may repost or share your posts on social media if it relates to
          500WS and our work.
        </p>
        <p style={indent}>
          We may also respond to questions, queries, or comments left on our
          social media channels. We may use information found on your profile to
          help us answer these. Please check your social media account
          preferences if you want to change the information you make public.
        </p>
        <p style={indent}>
          Our websites use “sharing buttons” which share our web pages to social
          media platforms. Please be aware that when you use these buttons,
          social media platforms may track these shares through your accounts.
        </p>
        <strong>Who has access to the personal information about you?</strong>
        <br />
        <br />
        <strong style={indent}>TRAINED STAFF</strong>
        <p style={indent}>
          The information we hold about you is only accessible by trained staff,
          volunteers, and contractors. We regularly review who has access to the
          information and do comprehensive checks on any contractors before we
          work with them.
        </p>
        <strong style={indent}>VENDORS</strong>
        <p style={indent}>
          We use other companies to help us manage and store personal data, and
          to carry out certain activities on our behalf. Our main vendors are
          listed below, but we may enlist the services of others from time to
          time:
        </p>
        <ul style={indent}>
          <li>
            <strong>AWS</strong> – provides cloud hosting for our “Request a
            Woman in STEMM” directory
          </li>
          <li>
            <strong>Mailchimp</strong> – email updates, sharing information
          </li>
          <li>
            <strong>Google GSuite</strong> – documents, spreadsheets, forms{' '}
          </li>
          <li>
            <strong>Squarespace</strong> – hosts the main 500WS website
          </li>
          <li>
            <strong>Donorbox</strong> – processes electronic donations
          </li>
          <li>
            <strong>PayPal</strong> – processes electronic donations and sales
          </li>
          <li>
            <strong>Google Analytics</strong> – captures basic analytics
            information about database access
          </li>
        </ul>
        <strong style={indent}>OTHER</strong>
        <p style={indent}>
          We may be required to disclose your personal information to the
          government or to third parties pursuant to law enforcement inquiries,
          court orders, subpoenas and similar requests, or when we otherwise
          believe in good faith that such disclosure is necessary or appropriate
          in connection with any activity that violates the law or may expose us
          to liability.
        </p>
        <strong>How do we keep the information about you safe?</strong>
        <p style={indent}>
          We use appropriate technical and organizational measures and
          precautions in order to protect your information and to prevent the
          loss, misuse, or alteration of your personal data.
        </p>
        <p style={indent}>
          While we work to keep the information about you safe, we cannot
          guarantee its security.
        </p>
        <strong>How long do we keep the information?</strong>
        <p style={indent}>
          We retain the information about you only for as long as necessary for
          the purposes for which the data was collected, except where necessary
          to meet our legal obligations or in order to establish, exercise,
          defend potential legal claims, or to pursue other legitimate
          interests.
        </p>
        <p style={indent}>
          If you request to receive no further contact from us, we will keep
          some basic information about you on our suppression list in order to
          avoid sending you unwanted materials in the future.
        </p>
        <strong>
          How can I access my information on the gage. Discover Brilliance
          platform and change it?
        </strong>
        <p style={indent}>
          If you have created a profile on the 500WS “gage. Discover Brillance”
          platform, you may update your profile information or eliminate it from
          the directory by logging into your profile.
        </p>
        <p style={indent}>
          In all other cases, please contact us at{' '}
          <a href="gage@500womenscientists.org">gage@500womenscientists.org</a>{' '}
          to make changes. However, please note that you have access to your
          personal information in the “gage. Discover Brillance” platform and
          you can make changes anytime.
        </p>
        <div id="eu">
          <strong>Residents of the European Economic Area (EEA)</strong>
          <p>
            Under EU data protection law, we are required to provide the
            following additional disclosures:
          </p>
          <strong style={indent}>IDENTITY OF THE CONTROLLER</strong>
          <p style={indent}>
            500WS acts as the controller of your personal data under EU law and
            is responsible for its processing.
          </p>
          <strong style={indent}>LAWFUL BASIS</strong>
          <p style={indent}>
            Under EU law, organizations must have a “lawful basis” to collect,
            use, and share personal data. The law allows for six ways to process
            personal data (and additional requirements apply for special
            categories of personal information). Three of these are relevant to
            the types of processing that 500WS carries out.
          </p>
          <p style={indent}>
            This includes{' '}
            <strong>information that is processed on the basis</strong> of:
          </p>
          <ul>
            <li>
              A person’s consent (e.g., to send you direct marketing by e-mail);
            </li>
            <li>
              Processing that is necessary for compliance with a legal
              obligation (e.g., to comply with our obligations as a
              not-for-profit to report fundraising); and{' '}
            </li>
            <li>
              Our legitimate interests (i.e., when the processing is necessary
              for the legitimate interest of the organization using the data or
              for a third party, as long as the use is fair and any potential
              adverse impact to the rights of the individual concerned are
              justified).
            </li>
          </ul>
          <p style={indent}>
            <strong>Consent:</strong> Where your consent is the basis for our
            processing of personal data, you may decline to give consent, or
            withdraw it at any time.
          </p>
          <p style={indent}>
            <strong>Legal obligation:</strong> We process under legal obligation
            where necessary in order to comply with a legal obligation imposed
            by EU or Member State law.
          </p>
          <p style={indent}>
            <strong>Legitimate interest:</strong> Under EU law, personal data
            may be legally collected and used if necessary in order to advance
            our legitimate interest or the legitimate interest of a third party.
          </p>
          <div style={indent}>
            <p style={indent}>Our legitimate interests include:</p>
            <ul>
              <li>
                <strong>Charity Governance;</strong> including delivery of our
                charitable purposes, statutory and financial reporting, and
                other regulatory compliance purposes;
              </li>
              <li>
                <strong>Administration and operational management;</strong>{' '}
                including responding to solicited enquires, providing
                information and services, research, events management, the
                administration of volunteers, and employment and recruitment
                requirements.
              </li>
              <li>
                <strong>Fundraising and Campaigning;</strong> including
                administering campaigns and donations, and sending marketing.{' '}
              </li>
            </ul>
          </div>
          <p style={indent}>
            If you disagree with the use of your personal information by us on
            the basis of a legitimate interest, please get in touch with us
            using the details below.
          </p>
          <strong style={indent}>DATA TRANSFERS</strong>
          <p style={indent}>
            <strong>Transfers to unaffiliated parties:</strong> We do
            comprehensive checks on any vendors that may have access to your
            personal information before we work with them. We adhere to a
            contract that governs how your personal data is accessed and
            managed.
          </p>
          <p style={indent}>
            <strong>International transfers:</strong> We do transfer your
            personal data outside the European Economic Area (“EEA”), including
            to the US. You may request a copy of the EU Standard Contractual
            Clauses or other relevant international transfer documentation by
            contacting us using the contact details below.
          </p>
          <strong style={indent}>RIGHTS OF INDIVIDUALS</strong>
          <p style={indent}>
            Under EU and Member State data protection laws, data subjects have
            the following rights in relation to their personal data:
          </p>
          <ul>
            <li>
              Right to request details about the personal data that we process,
              and obtain a copy of the data that we hold about them{' '}
            </li>
            <li>Correct or update their personal data subject to the above</li>
            <li>
              Port personal data that the data subject has provided to us, in
              machine readable format, to another supplier
            </li>
            <li>Erase the data that we hold about them in some cases</li>
            <li>Restrict or object to its processing in some cases</li>
            <li>
              Object to processing:
              <ul>
                <li>
                  Based on grounds relating to the individual’s particular
                  situation, where the processing is based on legitimate
                  interest, and
                </li>
                <li>
                  Where personal data is being processed for direct marketing
                  purposes
                </li>
              </ul>
            </li>
          </ul>
          <p style={indent}>
            These rights are not absolute and are subject to various conditions
            under applicable data protection and privacy legislation. In some
            cases, the exercise of these rights (for example, erasure,
            objection, restriction or the withholding or withdrawing of consent
            to processing) may make it impossible for us to provide services to
            you.
          </p>
          <p style={indent}>
            If you wish to assert your rights please address the relevant
            request to:
          </p>
          <p style={indent}>
            <strong>By email:</strong>{' '}
            <a href="mailto:pods@500womenscientists.org">
              pods@500womenscientists.org
            </a>
          </p>
          <p style={indent}>
            Data subjects also have the right to submit a complaint concerning
            our processing to the appropriate EU supervisory authority.
          </p>
        </div>
        <strong>Changes this Privacy Notice</strong>
        <p style={indent}>
          We change this Privacy Notice when we need to. If we make any
          significant changes in the way we treat your personal information, we
          will make this clear on our websites or by contacting you directly.
        </p>
        <strong>Use of directory information by third parties</strong>
        <p style={indent}>
          If you{' '}
          <strong>
            sign up for our gage. Discover Brilliance (formerly Request a Woman
            STEMM) directory your directory
          </strong>{' '}
          information will be displayed in our directory and available to the
          public. Other individuals will have access to it. For example, a
          journalist can see your (PROVIDE EXAMPLES) and save those details for
          their own records. In the same way, you will be able to access
          information publicly posted in our directory.
        </p>
        <p style={indent}>
          We ask individuals that access personal information through our
          directory to use it responsibly and respectfully, but 500WS does not
          have control over their actions. Accordingly, please be aware that if
          you post your information in our directory we will not be able to
          restrict how third parties may choose to use your information.
        </p>
        <strong>How to contact us</strong>
        <p style={indent}>
          This privacy notice was prepared to be as comprehensive as possible,
          but it does not include an exhaustive list of every aspect of our
          collection and use of personal information. However, we would be happy
          to provide any further information or explanation about our practices.
        </p>
        <p style={indent}>
          If you have any questions, comments or suggestions, please let us know
          by contacting us at{' '}
          <a href="mailto:pods@500womenscientists.org">
            pods@500womenscientists.org
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default Privacy;
