import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import SaveButton from '../../components/SaveButton';

const Feedback = (props) => {
  let [wordCount, setWordCount] = useState(0);
  const { t } = useTranslation();

  const wordCounter = (e) => {
    let wordsList = e.target.value.split(' ');
    setWordCount(wordsList.length);
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{t('Feedback')}</h1>
      <h3 className={styles.subhead}>
        {t('Please tell us how we can improve your experience.')}
      </h3>
      <div className={styles.form}>
        <textarea
          className={styles.input}
          onKeyUp={(event) => wordCounter(event)}
        ></textarea>
        <div className={styles.wordCount}>{wordCount}/250</div>
      </div>
      <div className={styles.save}>
        <SaveButton />
      </div>
    </div>
  );
};

export default Feedback;
