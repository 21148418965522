import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import '@fortawesome/fontawesome-free';
import 'components-font-awesome';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { Box } from '@material-ui/core';
import linkIcon from '../../assets/images/pods/icons/Link.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: 20,
  },
  container: {
    //overflow: 'auto',
    marginLeft: 15,
  },
  paper: {
    boxShadow: '0px 2px 6px 0 rgb(65 36 77 / 5%)',
    borderRadius: 0,
    padding: '32px',

    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginBottom: 20,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  linkIcon: {
    height: 32,
    margin: '0px 10px -5px -3px',
  },
  pos: {
    marginBottom: 12,
  },
  table: {
    minWidth: 650,
  },
  memberGrid: {
    maxHeight: 500,
  },
  selectWrapper: {
    width: '100%',
    textAlign: 'left',
  },
  active: {
    color: '#6e3694',
    cursor: 'pointer',
  },
  buttonWrapper: {
    display: 'inline',
  },
  linkWrapper: {
    textAlign: 'left',
  },
  label: {
    fontFamily: 'Oswald',
    fontSize: '1rem',
    fontWeight: 500,
    textTransform: 'uppercase',
    letterSpacing: '0.2px',

    color: '#6e3694',
  },
  area: {
    width: '100%',
    border: 'grey 1px solid',
  },
  textInput: {
    width: 400,
    fontFamily: "'Archivo Narrow'",
    color: 'rgba(26,26,26,.7)',
    fontSize: '1rem',
  },
  centered: {
    '& input': {
      textAlign: 'center',
    },
  },
}));

const PodDetails = (props) => {
  const { pod, setPayload, editing, isAdmin, isCoordinator } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  //form fields
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [facebook, setFacebook] = useState(null);
  const [instagram, setInstagram] = useState(null);
  const [twitter, setTwitter] = useState(null);
  const [website, setWebsite] = useState(null);
  const [country, setCountry] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);

  useEffect(() => {
    if (pod) {
      setName(pod.name);
      setDescription(pod.description);
      setFacebook(pod.facebook);
      setInstagram(pod.instagram);
      setTwitter(pod.twitter);
      setWebsite(pod.website);

      setCountry(pod.country);
      setState(pod.state);
      setCity(pod.city);
      setLat(pod.location?.coordinates[1]);
      setLng(pod.location?.coordinates[0]);
    }
  }, [pod]);

  useEffect(() => {
    setPayload({
      id: pod.id,
      name,
      description,
      facebook,
      instagram,
      twitter,
      website,
      country,
      state,
      city,
      location: {
        coordinates: [lng, lat],
        type: 'Point',
      },
    });
  }, [
    pod,
    name,
    description,
    facebook,
    instagram,
    twitter,
    website,
    country,
    state,
    city,
    lat,
    lng,
    setPayload,
  ]);

  const clickLink = (link) => {
    if (link) {
      if (link.indexOf('http') > -1) {
        window.open(link, '_blank');
      } else {
        window.open('http://' + link, '_blank');
      }
    }
  };

  //get pod from query string
  return (
    <div className={classes.root}>
      {pod && (
        <div>
          {(editing || facebook || instagram || twitter || website) && (
            <Paper
              className={[classes.paper, styles.socialMediaWrapper].join(' ')}
            >
              <Typography
                style={{ flex: 1 }}
                className={styles.header}
                gutterBottom
              >
                {t('Pod Socials')}
              </Typography>
              {!editing && (
                <Grid container spacing={3} className={classes.linkWrapper}>
                  {facebook && (
                    <Grid item xs={6} align="left">
                      <div
                        className={`${styles.link} ${
                          pod.facebook ? classes.active : null
                        }`}
                        onClick={(e) => clickLink(pod.facebook)}
                      >
                        <i
                          title={t('Facebook Link')}
                          className={`fab fa-facebook fa-2x`}
                        />
                        <Typography
                          style={{ flex: 1, verticalAlign: 'super' }}
                          display="inline"
                          gutterBottom
                        >
                          {pod.facebook}
                        </Typography>
                      </div>
                    </Grid>
                  )}
                  {instagram && (
                    <Grid item xs={6} align="left">
                      <div
                        className={`${styles.link} ${
                          pod.instagram ? classes.active : null
                        }`}
                        onClick={(e) => clickLink(pod.instagram)}
                      >
                        <i
                          title={t('Instagram Link')}
                          className={`fab fa-instagram fa-2x`}
                        />
                        <Typography
                          style={{ flex: 1, verticalAlign: 'super' }}
                          display="inline"
                          gutterBottom
                        >
                          {pod.instagram}
                        </Typography>
                      </div>
                    </Grid>
                  )}
                  {twitter && (
                    <Grid item xs={6} align="left">
                      <div
                        className={`${styles.link} ${
                          pod.twitter ? classes.active : null
                        }`}
                        onClick={(e) => clickLink(pod.twitter)}
                      >
                        <i
                          title={t('Twitter Link')}
                          className={`fab fa-twitter-square fa-2x`}
                        />
                        <Typography
                          display="inline"
                          gutterBottom
                          style={{ flex: 1, verticalAlign: 'super' }}
                        >
                          {pod.twitter}
                        </Typography>
                      </div>
                    </Grid>
                  )}
                  {website && (
                    <Grid item xs={6} align="left">
                      <div
                        className={`${styles.link} ${
                          pod.website ? classes.active : null
                        }`}
                        onClick={(e) => clickLink(pod.website)}
                      >
                        <img
                          src={linkIcon}
                          alt={t('Webite Link')}
                          className={classes.linkIcon}
                          title={t('Webite Link')}
                        />

                        <Typography
                          style={{ flex: 1, verticalAlign: 'super' }}
                          display="inline"
                          gutterBottom
                        >
                          {pod.website}
                        </Typography>
                      </div>
                    </Grid>
                  )}
                </Grid>
              )}
              {editing && (
                <Box>
                  <Grid container spacing={3}>
                    <Grid item xs={6} align="left">
                      <Typography variant="body1" className={classes.label}>
                        {t('Facebook')}
                      </Typography>
                      <TextField
                        value={facebook || ''}
                        align="left"
                        className={`${classes.textInput} `}
                        placeholder="www.facebook.com/..."
                        // InputProps={{
                        //   startAdornment: (
                        //     <InputAdornment position="start">
                        //       http://
                        //     </InputAdornment>
                        //   ),
                        // }}
                        onChange={({ target }) => setFacebook(target.value)}
                      />
                    </Grid>
                    <Grid item xs={6} align="left">
                      <Typography variant="body1" className={classes.label}>
                        {t('Instagram')}
                      </Typography>
                      <TextField
                        value={instagram || ''}
                        align="left"
                        className={`${classes.textInput}`}
                        placeholder="www.instagram.com/..."
                        // InputProps={{
                        //   startAdornment: (
                        //     <InputAdornment position="start">
                        //       http://
                        //     </InputAdornment>
                        //   ),
                        // }}
                        onChange={({ target }) => setInstagram(target.value)}
                      />
                    </Grid>
                    <Grid item xs={6} align="left">
                      <Typography variant="body1" className={classes.label}>
                        {t('Twitter')}
                      </Typography>
                      <TextField
                        value={twitter || ''}
                        align="left"
                        className={`${classes.textInput} `}
                        placeholder="www.twitter.com/..."
                        // InputProps={{
                        //   startAdornment: (
                        //     <InputAdornment position="start">
                        //       http://
                        //     </InputAdornment>
                        //   ),
                        // }}
                        onChange={({ target }) => setTwitter(target.value)}
                      />
                    </Grid>
                    <Grid item xs={6} align="left">
                      <Typography variant="body1" className={classes.label}>
                        {t('Website')}
                      </Typography>
                      <TextField
                        value={website || ''}
                        align="left"
                        className={`${classes.textInput} `}
                        placeholder="www.podwebsite.com"
                        // InputProps={{
                        //   startAdornment: (
                        //     <InputAdornment position="start">
                        //       http://
                        //     </InputAdornment>
                        //   ),
                        // }}
                        onChange={({ target }) => setWebsite(target.value)}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Paper>
          )}

          {editing && isAdmin && (
            <Paper className={classes.paper}>
              <Typography
                style={{ flex: 1 }}
                className={styles.header}
                gutterBottom
              >
                {t('Pod Name')}
              </Typography>

              <Box className={styles.content}>
                <TextField
                  value={name}
                  className={classes.textInput}
                  onChange={({ target }) => setName(target.value)}
                />
              </Box>
            </Paper>
          )}
          <Paper className={classes.paper}>
            <Typography
              style={{ flex: 1 }}
              className={styles.header}
              gutterBottom
            >
              {t('Pod Description')}
            </Typography>

            <Box className={styles.content}>
              {!editing && (
                <Typography className={styles.text}>
                  {description || t('please_enter_description')}
                </Typography>
              )}
              {editing && (
                <textarea
                  autoFocus
                  type="textarea"
                  className={classes.area}
                  rows="8"
                  value={description || ''}
                  onChange={({ target }) => setDescription(target.value)}
                ></textarea>
              )}
            </Box>
          </Paper>

          {!pod.isRemote && (
            <Paper className={classes.paper}>
              <Typography
                style={{ flex: 1 }}
                className={styles.header}
                gutterBottom
              >
                {t('Pod Location')}
              </Typography>

              <Box className={styles.content}>
                <Grid container spacing={3}>
                  <Grid item xs={4} align="left">
                    <Typography variant="body1" className={classes.label}>
                      {t('Country')}
                    </Typography>
                    {(!editing || isCoordinator) && (
                      <Typography variant="body1">
                        {pod.country || 'none'}
                      </Typography>
                    )}
                    {editing && isAdmin && (
                      <TextField
                        value={country}
                        onChange={({ target }) => setCountry(target.value)}
                      />
                    )}
                  </Grid>
                  <Grid item xs={4} align="left">
                    <Typography variant="body1" className={classes.label}>
                      {t('State')}
                    </Typography>
                    {(!editing || isCoordinator) && (
                      <Typography variant="body1">
                        {pod.state || 'none'}
                      </Typography>
                    )}
                    {editing && isAdmin && (
                      <TextField
                        value={state}
                        onChange={({ target }) => setState(target.value)}
                      />
                    )}
                  </Grid>
                  <Grid item xs={4} align="left">
                    <Typography variant="body1" className={classes.label}>
                      {t('City')}
                    </Typography>
                    {(!editing || isCoordinator) && (
                      <Typography variant="body1">
                        {pod.city || 'none'}
                      </Typography>
                    )}
                    {editing && isAdmin && (
                      <TextField
                        value={city}
                        onChange={({ target }) => setCity(target.value)}
                      />
                    )}
                  </Grid>
                  <Grid item xs={4} align="left">
                    <Typography variant="body1" className={classes.label}>
                      {t('Latitude')}
                    </Typography>
                    {(!editing || isCoordinator) && (
                      <Typography variant="body1">
                        {pod.location?.coordinates[1]}
                      </Typography>
                    )}
                    {editing && isAdmin && (
                      <TextField
                        value={lat}
                        type="number"
                        onChange={({ target }) => setLat(target.value)}
                      />
                    )}
                  </Grid>
                  <Grid item xs={4} align="left">
                    <Typography variant="body1" className={classes.label}>
                      {t('Longitude')}
                    </Typography>
                    {(!editing || isCoordinator) && (
                      <Typography variant="body1">
                        {pod.location?.coordinates[0]}
                      </Typography>
                    )}
                    {editing && isAdmin && (
                      <TextField
                        value={lng}
                        type="number"
                        onChange={({ target }) => setLng(target.value)}
                      />
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          )}
        </div>
      )}
    </div>
  );
};

export default PodDetails;
