import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Input from '../../../components/Input';
import { rootUrl } from '../../../config';

import styles from './index.module.scss';
import { Button } from '@material-ui/core';

const ForgotPassword = (props) => {
  const { history } = props;
  const { location } = history;
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (location.search && location.search.includes('email=')) {
      const [, value] = location.search.split('email=');
      if (value) {
        setEmail(value);
      }
    }
  }, [location]);

  const reset = () => {
    if (!email || !email.includes('@')) {
      setError('Please enter a valid email');
    } else {
      axios.post(`${rootUrl}/api/forgot-pod-password`, { email }, {});
      setSubmitted(true);
    }
  };

  return (
    <div className={styles.container}>
      {!submitted && (
        <>
          <div className={styles.image}></div>
          <h1 className={styles.title}>{t('Forgot your password?')}</h1>
          <p className={styles.subtitle}>
            {t(
              "Enter your email address and we'll send you an email with instructions for resetting your password"
            )}
          </p>
          <div className={styles.form}>
            <label style={{ paddingLeft: 50 }} htmlFor="email">
              {t('Email')}
            </label>
            <Input
              type="text"
              id="email"
              className={styles.emailInput}
              maxLength={255}
              value={email}
              onChange={(e) => {
                setError('');
                setEmail(e.target.value);
              }}
              error={error}
            />
            <div className={styles.buttons}>
              <Button
                className={`${styles.purpleButton} ${styles.resetButton}`}
                onClick={reset}
              >
                {t('Reset Password')}
              </Button>
              <div>
                or{' '}
                <Link className={styles.loginLink} to="/">
                  {t('Login')}
                </Link>
              </div>
            </div>
            <div className={styles.privacyWrapper}>
              <a
                className={styles.privacy}
                style={{ marginLeft: 'auto' }}
                href="mailto:pods@500womenscientists.org"
                rel="noopener noreferrer"
              >
                {t('Contact Us')}
              </a>
            </div>
          </div>
        </>
      )}
      {submitted && (
        <p style={{ margin: 'auto' }}>
          <Trans className={styles.ty}>
            {t('Thank you for submitting a password reset')}{' '}
            <Link className={styles.loginLink} to="/">
              {t('Login')}
            </Link>
            .
          </Trans>
        </p>
      )}
    </div>
  );
};

export default ForgotPassword;
