import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import '@fortawesome/fontawesome-free';
import 'components-font-awesome';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import { DataGrid } from '@material-ui/data-grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { IconButton, Link, MenuItem } from '@material-ui/core';
import trashIcon from '../../assets/images/pods/icons/Delete.svg';
import searchIcon from '../../assets/images/pods/icons/Search.svg';

const actions = [
  // {
  //   icon: <i className="fas fa-edit" aria-hidden="true"></i>,
  //   name: 'Edit Account',
  // },
  {
    icon: <img src={trashIcon} alt="delete account" />,
    name: 'Delete Account',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: 20,
  },
  container: {
    //overflow: 'auto',
    marginLeft: 15,
  },
  paper: {
    boxShadow: '0px 2px 6px 0 rgb(65 36 77 / 5%)',
    borderRadius: 0,
    padding: '32px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  table: {
    minWidth: 650,
  },
  grid: {
    maxHeight: 800,
    minHeight: 500,
    borderRadius: 0,
    '& .MuiDataGrid-colCellWrapper': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      '& .MuiDataGrid-colCellTitle': {
        color: 'rgba(113, 59, 144, 1)',
        fontWeight: 900,
      },
    },
    '& .MuiDataGrid-window': {
      backgroundColor: 'white',
    },
    '& .MuiTablePagination-selectRoot': {
      lineHeight: '.2rem',
    },
    '& .MuiDataGrid-colCellTitle': {
      fontWeight: '500 !important',
    },
  },
  selectWrapper: {
    width: '100%',
    textAlign: 'left',
  },
  speedDial: {
    '& .MuiSpeedDial-fab': {
      backgroundColor: 'rgba(113, 59, 144, 1)',
      width: 36,
      height: 28,
    },
  },
  speedAction: {
    color: 'rgba(113, 59, 144, 1)',
  },
  statusSelect: {
    minWidth: 132,
    marginTop: 15,
    '& .MuiSelect-root': {
      lineHeight: '.5rem',
    },
  },
  cellStatus: {
    color: 'rgba(26,26,26,.7)',
    fontFamily: 'Archivo Narrow',
    fontSize: '1rem',
    fontWeight: 500,
  },
  cellEmail: {
    fontFamily: 'Archivo Narrow',
    fontSize: '1rem',
    fontWeight: 700,
    color: '#6e3694 !important',
    '& a': {
      color: '#6e3694 !important',
      textDecoration: 'none !important',
      '&:hover': {
        color: '#2c0040 !important',
        textDecoration: 'underline !important',
      },
      '&:visited': {
        color: '#6e3694 !important',
      },
    },
  },
  cellName: {
    color: 'rgba(26,26,26,.9)',
    fontFamily: 'Archivo Narrow',
    fontSize: '1rem',
    fontWeight: 700,
    textAlign: 'left',
  },
}));

const PodMembers = (props) => {
  const {
    history,
    pod,
    members,
    setMembers,
    editing,
    membersToDelete,
    setMembersToDelete,
    statuses,
    setStatuses,
    isCoordinator,
    isAdmin,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [filteredMembers, setFilteredMembers] = useState([]);

  const [filterText, setFilterText] = useState(null);

  const types = [
    { value: 'approved', label: t('Approved') },
    { value: 'pending', label: t('Pending') },
    { value: 'rejected', label: t('Rejected') },
    { value: 'removed', label: t('Removed') },
    { value: 'coordinator', label: t('Coordinator') },
  ];

  const filterChange = useCallback(
    (text, people) => {
      //people = people || members;
      let filtered = people.filter(
        (m) => !text || m.name.toLowerCase().includes(text.toLowerCase())
      );

      //remove anyone to be deleted if we are editing
      if (editing && membersToDelete.length) {
        filtered = filtered.filter(
          (f) => !membersToDelete.some((m) => m === f.podProfileId)
        );
      }

      const sorted = filtered.sort((a, b) => (a.name < b.name ? -1 : 1));
      let finalMembers = [];
      //if this is a coordinator, put pending users at the op
      if (isCoordinator || isAdmin) {
        finalMembers = sorted
          .filter((m) => m.status === 'pending')
          .concat(sorted.filter((m) => m.status !== 'pending'))
          .flat();
      } else {
        finalMembers = sorted;
      }

      setFilteredMembers(finalMembers);

      if (filterText !== text) {
        setFilterText(text);
      }
    },
    [membersToDelete, editing, filterText, isAdmin, isCoordinator]
  );

  useEffect(() => {
    //user hit cancel or we're done
    if (!editing) {
      setMembersToDelete([]);
    }
  }, [editing, setMembersToDelete]);

  useEffect(() => {
    if (pod) {
      const podMembers = pod.podMembers.filter((m) => m.podProfile?.user);
      console.log(
        'Bad Accounts: ',
        pod.podMembers.filter((m) => !m.podProfile?.user)
      );
      podMembers.forEach((member) => {
        member.id = member.podProfileId;
        member.name = member.podProfile?.name;
        member.email = member.podProfile?.user?.email;
      });

      setMembers(podMembers); // to start filter
      setStatuses([]); //reset, else we will pull in values from previous pod selection
      //doesn't consistantly trigger filter change, manual call
      filterChange(filterText, podMembers);
    }
  }, [pod, setMembers, filterText, filterChange, setStatuses]);

  const titleCase = (str) => {
    if (str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    } else {
      return '';
    }
  };

  const speedyClick = useCallback(
    (e, action, user) => {
      // setActiveUser(user);

      if (action.name === 'Edit Account') {
        history.push('/profile/' + user.podProfile.id);
      } else if (action.name === 'Delete Account') {
        // add id to members to delete array
        const toDelete = [...membersToDelete];
        toDelete.push(user.id);
        setMembersToDelete(toDelete);
      }
    },
    [history, membersToDelete, setMembersToDelete]
  );

  const handleStatusChange = useCallback(
    (e, member) => {
      const statusesCopy = [...statuses];
      const foundStatus = statusesCopy.find(
        (s) => s.id === member.podProfileId
      );
      if (foundStatus) {
        foundStatus.status = e.target.value;
      } else {
        // maybe a coordinator was just demoted
        statusesCopy.push({ id: member.podProfileId, status: e.target.value });
      }

      setStatuses(statusesCopy);
    },
    [statuses, setStatuses]
  );

  const dataGrid = useMemo(() => {
    const columns = [
      { field: 'id', hide: true },
      {
        field: 'name',
        headerName: t('Member'),
        headerClassName: styles.tableHeader,
        cellClassName: classes.cellName,
        width: 250,
        align: 'left',
      },
      {
        field: 'email',
        headerName: t('Email'),
        headerClassName: styles.tableHeader,
        cellClassName: classes.cellEmail,
        width: 300,
        align: 'left',
        renderCell: ({ row }) => (
          <Link variant="body2" href={`mailto:${row?.email}`}>
            {' '}
            {row?.email}
          </Link>
        ),
      },
      {
        field: 'gage',
        headerName: t('Gage'),
        headerClassName: styles.tableHeader,
        cellClassName: classes.cellEmail,
        width: 100,
        align: 'left',
        renderCell: ({ row }) =>
          row &&
          row.podProfile.user.applications.some((a) => a.name === 'gage') && (
            <Link
              variant="body2"
              target="_blank"
              href={`${process.env.REACT_APP_GAGE_URL}profile/${row.podProfile.userId}`}
            >
              Profile
            </Link>
          ),
      },
      {
        field: 'status',
        headerName: t('Status'),
        headerClassName: styles.tableHeader,
        cellClassName: classes.cellStatus,
        width: 150,
        align: 'left',
        renderCell: ({ row }) => (
          <div>
            {editing && (
              <Select
                value={
                  statuses.find((s) => s.id === row?.podProfileId)?.status ||
                  row?.status
                }
                variant="outlined"
                className={classes.statusSelect}
                onChange={(e) => handleStatusChange(e, row)}
              >
                {types.map((type) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
            )}

            {!editing && <div>{t(titleCase(row?.status))}</div>}
          </div>
        ),
      },
    ];

    if (editing) {
      columns.push({
        field: 'none',
        headerName: 'Actions',
        sortable: false,
        headerClassName: styles.tableHeader,
        width: 250,
        align: 'left',
        renderCell: ({ row }) =>
          actions.map((action) => (
            <IconButton
              className={classes.speedAction}
              key={action.name}
              title={action.name}
              onClick={(e) => speedyClick(e, action, row)}
            >
              {action.icon}
            </IconButton>
          )),
      });
    }

    return (
      <DataGrid
        pageSize={20}
        className={classes.grid}
        rowsPerPageOptions={[5, 20, 50, 100]}
        pagination
        columns={columns}
        rows={filteredMembers}
      />
    );
  }, [
    filteredMembers,
    editing,
    statuses,
    types,
    handleStatusChange,
    speedyClick,
    classes,
    t,
  ]);

  useEffect(() => {
    if (members && members.length) {
      if (!statuses.length) {
        const temp = [];
        members.forEach((member) => {
          temp.push({
            id: member.podProfileId,
            status:
              statuses.find((s) => s.id === member.podProfileId)?.status ||
              member?.status,
          });
        });

        setStatuses(temp);
      }
      // make sure filter is still correctly reflected
      //filterChange(filterText, members);
    }
  }, [members, statuses, setStatuses, filterText]); // filterChange,

  //get pod from query string
  return (
    <Paper
      className={`${classes.paper} ${styles.memberSection} ${classes.root}`}
    >
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Typography className={styles.header} gutterBottom>
            {t('pod_members')}{' '}
          </Typography>
        </Grid>
        <Grid item xs></Grid>
        <Grid item xs={5}>
          <TextField
            className={[classes.margin, styles.memberFilter].join(' ')}
            value={filterText || ''}
            id="memeber-search"
            onChange={(e) => filterChange(e.target.value, members)}
            label={t('Filter')}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={searchIcon} alt="search" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      {dataGrid}
    </Paper>
  );
};

export default PodMembers;
