import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
//import { useTranslation } from 'react-i18next';

import styles from './user-management.module.scss';
import '@fortawesome/fontawesome-free';
import 'components-font-awesome';
import { rootUrl } from '../../config';
import importantIcon from '../../assets/images/pods/icons/Warning.svg';
import checkIcon from '../../assets/images/pods/icons/Checkmark.svg';
import starIcon from '../../assets/images/pods/icons/Star.svg';
import trashIcon from '../../assets/images/pods/icons/Delete.svg';
import zoomIcon from '../../assets/images/pods/icons/Search.svg';
import closeIcon from '../../assets/images/pods/icons/Close.svg';

import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Link,
} from '@material-ui/core';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import {
  Autocomplete,
  // SpeedDial,
  // SpeedDialAction,
  // SpeedDialIcon,
} from '@material-ui/lab';
import UserDetails from './user-details';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  rootEmpty: {
    flexDirection: 'column',
    '& .ant-empty-img-1': {
      fill: theme.palette.type === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.type === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.type === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.type === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.type === 'light' ? '0.8' : '0.08',
      fill: theme.palette.type === 'light' ? '#f5f5f5' : '#fff',
    },
  },
  label: {
    marginTop: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  table: {
    minWidth: 650,
  },
  red: {
    color: 'red',
    marginRight: -5,
    paddingLeft: 5,
    fontWeight: 900,
  },
  inputWrapper: {
    maxWidth: 300,
    textAlign: 'left',
    margin: '20px 10px',
    '& .MuiOutlinedInput-input': {
      padding: '6px 14px',
      paddingRight: 32,
    },
  },
  grid: {
    borderRadius: 0,
    '& .MuiDataGrid-colCellWrapper': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      '& .MuiDataGrid-colCellTitle': {
        color: 'rgba(113, 59, 144, 1)',
        fontWeight: 900,
      },
    },
    '& .MuiDataGrid-window': {
      backgroundColor: 'white',
    },
    '& .MuiTablePagination-selectRoot': {
      lineHeight: '.2rem',
    },
  },
  speedDial: {
    '& .MuiSpeedDial-fab': {
      backgroundColor: 'rgba(113, 59, 144, 1)',
      width: 36,
      height: 28,
    },
  },
  speedAction: {
    color: 'rgba(113, 59, 144, 1)',
  },
  gold: {
    color: 'gold',
  },
  statusColumn: {
    display: 'inline',
  },
  filterBar: {
    display: 'flex',
    flexDirection: 'vertical',
  },
  filterInput: {
    maxWidth: 300,
    '& .MuiInputBase-formControl': {
      paddingBottom: '5px !important',
      paddingTop: '5px !important',
    },
  },
  filter: {
    '& .MuiSelect-root': {
      paddingBottom: '8px !important',
      paddingTop: '8px !important',
    },
    '& .MuiAutocomplete-inputRoot': {
      paddingBottom: '5px !important',
      paddingTop: '5px !important',
    },
    '& input': {
      paddingBottom: '3px !important',
      paddingTop: '3px !important',
    },
    '& .MuiAutocomplete-endAdornment': {
      top: 'unset',
    },
  },
  cellName: {
    color: 'rgba(26,26,26,.9)',
    fontFamily: 'Archivo Narrow',
    fontSize: '1rem',
    fontWeight: 700,

    '& .MuiIconButton-label': {
      color: 'rgba(26,26,26,.9)',
      fontFamily: 'Archivo Narrow',
      fontSize: '1rem',
      fontWeight: 700,
    },
  },
  cellEmail: {
    fontFamily: 'Archivo Narrow',
    fontSize: '1rem',
    fontWeight: 700,
    color: '#6e3694 !important',
    '& a': {
      fontFamily: 'Archivo Narrow',
      fontSize: '1rem',
      fontWeight: 700,
      color: '#6e3694 !important',
      textDecoration: 'none !important',
      '&:hover': {
        color: '#2c0040 !important',
        textDecoration: 'underline !important',
      },
      '&:visited': {
        color: '#6e3694 !important',
      },
    },
  },
  cellNumber: {
    color: 'rgba(26,26,26,.9)',
    fontFamily: 'Archivo Narrow',
    fontSize: '1rem',
    fontWeight: 500,

    '& .MuiIconButton-label': {
      color: 'rgba(26,26,26,.9)',
      fontFamily: 'Archivo Narrow',
      fontSize: '1rem',
      fontWeight: 500,
    },
  },
  cellFont: {
    color: '#6e3694',
    fontSize: '1.2rem',
  },
}));

const CustomLoadingOverlay = () => {
  return (
    <GridOverlay>
      <div style={{ position: 'absolute', top: 0, width: '100%' }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
};

const CustomNoRowsOverlay = () => {
  const classes = useStyles();
  return (
    <GridOverlay className={classes.rootEmpty}>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <div className={classes.label}>No Rows</div>
    </GridOverlay>
  );
};

const actions = [
  {
    icon: <img src={zoomIcon} alt="view account" />,
    name: 'View Account',
  },
  {
    icon: <img src={starIcon} alt="promote/demote" />,
    name: 'Promote/Demote Admin',
  },
  {
    icon: <img src={trashIcon} alt="delete account" />,
    name: 'Delete Account',
  },
];

const types = ['Admin', 'Member', 'Coordinator', 'Pending', 'Rejected'];

const typeItems = () => {
  const items = [];
  items.push(
    <MenuItem key={'type' + 0} value={null}>
      <span style={{ opacity: 0.4 }}>Profile Type</span>
    </MenuItem>
  );
  types.forEach((s) =>
    items.push(
      <MenuItem key={s.toString()} value={s}>
        {s}
      </MenuItem>
    )
  );
  return items;
};

const UserManagement = (props) => {
  const {
    history,
    users,
    setUsers,
    loaded,
    me,
    setSnacking,
    setMessage,
    setSeverity,
  } = props;
  //const { t } = useTranslation();
  const classes = useStyles();
  const [filteredUsers, setfilteredUsers] = useState(users);
  const [gridHeight, setGridHeight] = useState(500);
  const boxRef = createRef(null);
  // const [open, setOpen] = useState(null);
  const [filterText, setFilterText] = useState('');
  const [profileType, setProfileType] = useState(null);
  const [podLocations, setPodLocations] = useState([]);
  const [podLocation, setPodLocation] = useState(null);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [details, setDetails] = useState(false);
  const [activeUser, setActiveUser] = useState(null);
  const [openAdminConfirm, setOpenAdminConfirm] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

  const applyFilter = useCallback(
    (text, profileType, location) => {
      try {
        let filtered = users;
        //filter logic here
        if (text) {
          filtered = filtered.filter(
            (u) =>
              u.name.toLowerCase().includes(text.toLowerCase()) ||
              u.email.toLowerCase().includes(text.toLowerCase())
          );
        }

        if (profileType) {
          switch (profileType) {
            case 'Admin':
              filtered = filtered.filter((u) => u.isAdmin);
              break;
            case 'Member':
              filtered = filtered.filter((u) => u.role === 'pod_member');
              break;
            case 'Coordinator':
              filtered = filtered.filter((u) => u.isCoordinator);
              break;
            case 'Pending':
              // search through pods to find if any are rejected
              filtered = filtered.filter((u) =>
                u.podProfile.podMembers.some((p) => p.status === 'pending')
              );
              break;
            case 'Rejected':
              // search through pods to find if any are rejected
              filtered = filtered.filter((u) =>
                u.podProfile.podMembers.some((p) => p.status === 'rejected')
              );
              break;
            default:
              break;
          }
        }

        if (location) {
          filtered = filtered.filter((u) =>
            u.podProfile.pods.some(
              (p) => p.name.toLowerCase() === location.toLowerCase()
            )
          );
        }

        setfilteredUsers(filtered);
      } catch (error) {
        console.error(error);
      }
    },
    [users]
  );

  useEffect(() => {
    if (users) {
      console.log('filtering');
      applyFilter(filterText, profileType, podLocation);

      const allLocations = users.map((u) => u.podProfile.pods).flat();

      setPodLocations(
        Array.from(new Set(allLocations.map((loc) => loc.name))).sort()
      );
    }
  }, [users, applyFilter, filterText, podLocation, profileType]);

  useEffect(() => {
    if (boxRef.current) {
      const h = boxRef.current.clientHeight + 100;
      if (h !== gridHeight) {
        setGridHeight(h);
      }
    }
  }, [gridHeight, boxRef]);

  const filterKeyed = (e) => {
    if (e.keyCode === 13) {
      applyFilter(filterText, profileType, podLocation);
    }
  };

  const filterChanged = (e) => {
    const val = e.target.value;
    setFilterText(val);
    applyFilter(val, profileType, podLocation);
  };

  const clearFilter = () => {
    setFilterText('');
    applyFilter('', profileType, podLocation);
  };

  const sorting = (e, ...o) => {};

  const speedyClick = useCallback(
    (e, action, user) => {
      // set user's role for application
      user.roles.forEach((userRole) => {
        if (userRole.userApplications.applicationId === 2) {
          user.role = userRole.role;
        }
      });
      setActiveUser(user);
      if (action.name === 'View Account') {
        history.push('/profile/' + user.id);
      } else if (action.name === 'Promote/Demote Admin') {
        // confirm
        setOpenAdminConfirm(true);
      } else if (action.name === 'Delete Account') {
        // confirm
        setOpenDeleteConfirm(true);
      }
    },
    [history]
  );

  const handleDialogClose = () => {
    setActiveUser(null);
    setOpenAdminConfirm(false);
    setOpenDeleteConfirm(false);
  };

  const deleteAccount = () => {
    axios
      .post(
        `${rootUrl}/api/user/delete`,
        {
          id: activeUser.id,
          profileId: activeUser.podProfile.id,
        },
        { withCredentials: true }
      )
      .then((results) => {
        if (results.status === 200 && results.data) {
          // remove user from model
          const updateUsers = [...users];
          const index = updateUsers.findIndex((u) => u.id === activeUser.id);
          updateUsers.splice(index, 1);
          setUsers(updateUsers);

          setMessage('Account Deleted!');
          setSeverity('success');
          setSnacking(true);
          setActiveUser(null);
          setOpenAdminConfirm(false);
          setOpenDeleteConfirm(false);
        }
      });
  };

  const changeAccountRole = () => {
    let role;
    activeUser.roles.forEach((r) => {
      if (r.userApplications.applicationId === 2) {
        role = r.role === 'pod_member' ? 'pod_admin' : 'pod_member';
      }
    });
    axios
      .post(
        `${rootUrl}/api/user/change/role`,
        {
          id: activeUser.id,
          userRole: role,
        },
        { withCredentials: true }
      )
      .then((results) => {
        if (results.status === 200 && results.data) {
          const updateUsers = [...users];
          const updatedUser = updateUsers.find((u) => u.id === activeUser.id);
          // set user's role for application
          updatedUser.roles.forEach((userRole) => {
            if (userRole.userApplications.applicationId === 2) {
              userRole.role = role;
              updatedUser.role = role;
              updatedUser.isAdmin = role === 'pod_admin';
            }
          });
          setUsers(updateUsers);
          if (role === 'pod_member') {
            setMessage('Account has been granted adminstrative permissions!');
          } else {
            setMessage(
              'Account has been had adminstrative permissions revoked!'
            );
          }

          setSeverity('success');
          setSnacking(true);
          setActiveUser(null);
          setOpenAdminConfirm(false);
          setOpenDeleteConfirm(false);
        }
      });
  };

  const dataGrid = useMemo(() => {
    return (
      <DataGrid
        autoHeight={true}
        pageSize={15}
        className={classes.grid}
        rowsPerPageOptions={[5, 15, 50, 100]}
        disableClickEventBubbling
        pagination
        components={{
          loadingOverlay: CustomLoadingOverlay,
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        onSortModelChange={sorting}
        loading={!loaded}
        columns={[
          { field: 'id', hide: true },
          {
            field: 'name',
            headerName: 'Name',
            headerClassName: styles.tableHeader,
            cellClassName: classes.cellName,
            align: 'left',
            width: 275,
          },
          {
            field: 'email',
            headerName: 'Email',
            headerClassName: styles.tableHeader,
            cellClassName: classes.cellEmail,
            align: 'left',
            width: 275,
            renderCell: ({ row }) => (
              <Link variant="body2" href={`mailto:${row?.email}`}>
                {' '}
                {row?.email}
              </Link>
            ),
          },
          {
            field: 'gage',
            headerName: 'Gage',
            headerClassName: styles.tableHeader,
            cellClassName: classes.cellEmail,
            sortable: false,
            width: 100,
            align: 'left',
            renderCell: ({ row }) =>
              row &&
              row.applications.some((a) => a.name === 'gage') && (
                <Link
                  variant="body2"
                  target="_blank"
                  href={`${process.env.REACT_APP_GAGE_URL}profile/${row.podProfile.userId}`}
                >
                  Profile
                </Link>
              ),
          },
          {
            field: 'podCount',
            headerName: 'Number of Pods',
            headerClassName: styles.tableHeader,
            cellClassName: styles.cellNumber,
            headerAlign: 'center',
            width: 155,
            renderCell: ({ row }) => (
              <div style={{ margin: 'auto' }}>
                <IconButton
                  color="primary"
                  className={classes.cellFont}
                  onClick={(e) => {
                    setDetails(row);
                    setDetailsOpen(true);
                  }}
                  component="button"
                >
                  {row?.podCount}
                  {row?.podProfile.podMembers.some(
                    (m) => m.status === 'pending'
                  ) ? (
                    <img
                      src={importantIcon}
                      className={styles.attention}
                      alt="Important"
                    />
                  ) : null}
                </IconButton>
              </div>
            ),
          },
          {
            field: 'isAdmin',
            headerName: 'Admin',
            headerClassName: styles.tableHeader,
            cellClassName: styles.cellCenter,
            headerAlign: 'center',
            width: 75,
            renderCell: ({ row }) => (
              <div style={{ margin: 'auto' }}>
                {row?.isAdmin && (
                  <img src={checkIcon} className={styles.check} alt="checked" />
                )}
              </div>
            ),
          },
          {
            field: 'isCoordinator',
            headerName: 'Coordinator',
            headerClassName: styles.tableHeader,
            cellClassName: styles.cellCenter,
            headerAlign: 'center',
            width: 120,
            renderCell: ({ row }) => (
              <div style={{ margin: 'auto' }}>
                {row?.isCoordinator && (
                  <img src={checkIcon} className={styles.check} alt="checked" />
                )}
              </div>
            ),
          },
          {
            field: 'none',
            headerName: 'Actions',
            sortable: false,
            headerClassName: styles.tableHeader,
            width: 250,
            align: 'left',
            renderCell: ({ row }) =>
              actions.map((action) => (
                <IconButton
                  className={classes.speedAction}
                  key={action.name}
                  title={action.name}
                  onClick={(e) => speedyClick(e, action, row)}
                >
                  {action.icon}
                </IconButton>
              )),
          },
        ]}
        rows={me && me.isAdmin ? filteredUsers : []}
      />
    );
  }, [classes, filteredUsers, loaded, me, speedyClick]);

  //get pod from query string
  return (
    <Box ref={boxRef} className={`${styles.container}`} height="100%">
      <div className={classes.filterBar}>
        <FormControl className={classes.inputWrapper}>
          <TextField
            variant="outlined"
            id="filter-input"
            className={`${classes.filterInput} ${classes.filter}`}
            autoComplete="off"
            value={filterText}
            placeholder="Search"
            onKeyDown={filterKeyed}
            onChange={filterChanged}
            InputProps={{
              autoComplete: 'off',
              startAdornment: (
                <InputAdornment position="start">
                  <img src={zoomIcon} alt="search" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={clearFilter}>
                    <img src={closeIcon} alt="close" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <FormControl className={classes.inputWrapper}>
          <Select
            id="profile-type-select"
            variant="outlined"
            value={profileType}
            className={classes.filter}
            displayEmpty
            onChange={(e) => {
              setProfileType(e.target.value);
              applyFilter(filterText, e.target.value, podLocation);
            }}
          >
            {typeItems()}
          </Select>
        </FormControl>
        <FormControl className={classes.inputWrapper}>
          <Autocomplete
            id="pod-location-select"
            variant="outlined"
            className={classes.filter}
            options={podLocations}
            getOptionLabel={(option) => option}
            onChange={(e) => {
              setPodLocation(e.target.innerText);
              applyFilter(filterText, profileType, e.target.innerText);
            }}
            style={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Pod Location"
                variant="outlined"
              />
            )}
          />

          {/* <Select
            id="pod-location-select"
            variant="outlined"
            value={podLocation}
            className={classes.filter}
            displayEmpty
            onChange={(e) => {
              setPodLocation(e.target.value);
              applyFilter(filterText, profileType, e.target.value);
            }}
          >
            {podLocationItems()}
          </Select> */}
        </FormControl>
      </div>
      {dataGrid}
      <UserDetails
        open={detailsOpen}
        setOpen={setDetailsOpen}
        data={details}
        history={history}
        setSnacking={setSnacking}
        setMessage={setMessage}
        setSeverity={setSeverity}
        users={users}
        setUsers={setUsers}
      />

      <Dialog
        open={openDeleteConfirm}
        onClose={handleDialogClose}
        aria-labelledby="form-dialog-delete"
      >
        <DialogTitle id="form-dialog-delete">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you certain you want to completely delete {activeUser?.name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={styles.cancelButton}
            onClick={handleDialogClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            className={styles.purpleButton}
            onClick={deleteAccount}
            color="primary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openAdminConfirm}
        onClose={handleDialogClose}
        aria-labelledby="form-dialog-admin"
      >
        <DialogTitle id="form-dialog-admin">
          {activeUser?.role === 'pod_member'
            ? 'Grant Admin Rights'
            : 'Revoke Admin Rights'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {activeUser?.role === 'pod_member'
              ? `Are you certain you want to grant ${activeUser?.name} administrative privileges?`
              : `Are you certain you want to revoke ${activeUser?.name}'s administrative privileges?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={`user ${styles.cancelButton}`}
            onClick={handleDialogClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            className={styles.purpleButton}
            onClick={changeAccountRole}
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UserManagement;
