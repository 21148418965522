import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import StoreContext from '../../../state/context/store';
import styles from './index.module.scss';
import Input from '../../../components/Input';
import { Link } from 'react-router-dom';
import { loginPods } from '../../../state/actions/me';
import { rootUrl, application } from '../../../config';
import ReCAPTCHA from 'react-google-recaptcha';
import { Button } from '@material-ui/core';

const SignUp = (props) => {
  const { history } = props;
  const { t } = useTranslation();
  const [context, dispatch] = useContext(StoreContext);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullNameError, setFullNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [recaptchaError, setRecaptchaError] = useState('');
  const [recaptchaInvisibleError, setRecaptchaInvisibleError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [saving, setSaving] = useState(false);
  const [captcha, setCaptcha] = useState(null);
  const [existingUser, setExistingUser] = useState(null);
  const recaptchaRef = React.createRef();

  useEffect(() => {
    if (context.me.authenticated) {
      history.replace('/about');
    }
  }, [history, context.me.authenticated]);

  const register = async () => {
    setSaving(true);
    await axios
      .post(
        `${rootUrl}/api/register`,
        {
          name: fullName,
          email,
          password,
          application,
        },
        { withCredentials: true }
      )
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          loginPods(dispatch, response.data);
          console.log('Registered and logged in: ', response.data);
          props.history.push('/about');
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        }
      })
      .catch((error) => {
        setSaving(false);
        console.log('ERROR', error);
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.length > 0
        ) {
          const errorMessage = error.response.data[0].message;
          if (errorMessage.toLowerCase().includes('email')) {
            setEmailError(errorMessage);
          } else if (errorMessage.includes('password')) {
            setPasswordError(errorMessage);
          } else {
            setFullNameError(errorMessage);
          }
        }
      });
  };

  const linkAccounts = async () => {
    setSaving(true);
    await axios
      .post(
        `${rootUrl}/api/link-accounts`,
        {
          name: fullName,
          email,
          password,
          application,
        },
        { withCredentials: true }
      )
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          loginPods(dispatch, response.data);
          console.log('Registered and logged in: ', response.data);
          props.history.push('/about');
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        }
      })
      .catch((error) => {
        setSaving(false);
        console.log('ERROR', error);
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.length > 0
        ) {
          const errorMessage = error.response.data[0].message;
          if (errorMessage.toLowerCase().includes('email')) {
            setEmailError(errorMessage);
          } else if (errorMessage.includes('password')) {
            setPasswordError(errorMessage);
          } else {
            setFullNameError(errorMessage);
          }
        }
      });
  };

  const clear = () => {
    setEmail('');
    setPassword('');
    setPasswordError('');
    setExistingUser(null);
  };

  const save = async () => {
    let errors = 0;
    let token = null;
    let message = null;

    if (!fullName) {
      setFullNameError('Your name is required');
      errors++;
    }
    if (!email) {
      setEmailError('Your email is required');
      errors++;
    }
    if (!password || password.length < 8) {
      setPasswordError('Password must be at least 8 characters long');
      errors++;
    }

    //once the error is triggered, ignore future requests, will be handled by visible recaptcha
    //also waiting to make first request until other errors resolved
    if (!recaptchaInvisibleError && errors === 0) {
      try {
        setSaving(true);
        token = await recaptchaRef.current.executeAsync();
      } catch (ex) {
        console.error(ex);
        setSaving(false);
      }
      setSaving(false);

      if (!token) {
        setRecaptchaInvisibleError(
          'You appear suspicious, please check the box below'
        );
        errors++;
      }
    }

    if (!captcha && !token) {
      setRecaptchaError('Please confirm your humanity');
      errors++;
    }

    if (errors === 0) {
      // check email for registration in other applications
      await axios
        .post(
          `${rootUrl}/api/email-registration-check`,
          {
            name: fullName,
            email,
            password,
            application,
          },
          { withCredentials: true }
        )
        .then((response) => {
          if (response.status === 201 || response.status === 200) {
            const appUser = response.data;
            // this email is in-use
            if (appUser.id) {
              // get application accounts
              let orca = appUser.accounts.find((a) => a.application === 'orca');
              let gage = appUser.accounts.find((a) => a.application === 'gage');
              if (orca) {
                message =
                  'It appears that you have signed up for Orca using this email. Go to the Sign In page and log in.';
              }
              if (gage) {
                setExistingUser(gage);
                message =
                  'It appears that you have already signed up for Gage using this email. Would you like to link your Orca account? If not, clear the form and use a different email address.';
              }
              // email & password are in-use
              if (appUser.authenticated) {
                //message = "It appears that you already have an account with this email & passord.";
              }
              setPasswordError(message);
            }
            // this email is not in-use
            else {
              register();
            }
          } else {
            setPasswordError('There was an error validating your email.');
          }
        })
        .catch((err) => {
          setPasswordError(
            'There was an error validating your email. Error: ',
            err
          );
        });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.image}></div>
      <h1 className={styles.title}>{t('Welcome to Orca!')}</h1>
      <h3 className={styles.returnhead}>
        {t('Already a member?')}
        <span className={styles.signup} onClick={() => history.push('/')}>
          {t('Sign In')}
        </span>
      </h3>
      <h3 className={styles.subhead}>{t("First, let's create an account!")}</h3>
      <form id="signupForm" className={styles.form}>
        <label className={styles.signLabel} htmlFor="name">
          {t('Name')}
        </label>
        <Input
          type="text"
          id="name"
          className={styles.signInput}
          maxLength={255}
          onChange={(e) => {
            setFullNameError('');
            setFullName(e.target.value);
          }}
          error={fullNameError}
        />
        <label className={styles.signLabel} htmlFor="email">
          {t('Email')}
        </label>
        <Input
          type="text"
          id="email"
          className={styles.signInput}
          maxLength={255}
          onChange={(e) => {
            setEmailError('');
            setEmail(e.target.value);
          }}
          value={email}
          error={emailError}
        />
        <label className={styles.signLabel} htmlFor="pw">
          {t('Password')}
        </label>
        <Input
          type="password"
          id="pw"
          className={styles.signInput}
          maxLength={255}
          onChange={(e) => {
            setPasswordError('');
            setPassword(e.target.value);
          }}
          value={password}
          error={passwordError}
        />
        <ReCAPTCHA
          size="invisible"
          ref={recaptchaRef}
          sitekey={process.env.REACT_APP_ReCAPTCHA_INVISIBLE_SITE_KEY}
        />
        {recaptchaInvisibleError && (
          <div className={styles.rewrapper}>
            <label className={styles.invisiError}>
              {t(recaptchaInvisibleError)}
            </label>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_ReCAPTCHA_SITE_KEY}
              onChange={(e) => {
                setRecaptchaError('');
                // setRecaptchaInvisibleError('');
                setCaptcha(e);
              }}
            />
            <label className={styles.captchaError}>{t(recaptchaError)}</label>
          </div>
        )}
      </form>
      <div className={styles.signup}>
        {!existingUser && (
          <Button
            className={`${styles.purpleButton}`}
            onClick={save}
            disabled={saving}
          >
            {t('Sign Up')}{' '}
            {saving && (
              <i
                className={`${styles.spinner} fas fa-circle-notch fa-spin`}
                style={{ width: '16px', height: '16px', fontSize: '16px' }}
              ></i>
            )}
          </Button>
        )}
        {existingUser && (
          <div>
            <div className={styles.forgotPasswordWrapper}>
              <Link className={styles.forgotPassword} to="/forgot-password">
                {t('forgot password?')}
              </Link>
            </div>
            <Button
              className={`${styles.goldButton}`}
              onClick={linkAccounts}
              disabled={saving}
            >
              {t('Link Accounts')}{' '}
              {saving && (
                <i
                  className={`${styles.spinner} fas fa-circle-notch fa-spin`}
                  style={{ width: '16px', height: '16px', fontSize: '16px' }}
                ></i>
              )}
            </Button>
            <Button
              className={`${styles.purpleButton}`}
              onClick={clear}
              disabled={saving}
            >
              {t('Clear Form')}{' '}
            </Button>
            <div className={styles.privacyWrapper}>
              <a
                className={styles.privacy}
                style={{ marginLeft: 'auto' }}
                href="mailto:pods@500womenscientists.org"
                rel="noopener noreferrer"
              >
                {t('Contact Us')}
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SignUp;
